import { registryApi } from '.';
import uniqBy from 'lodash/uniqBy';
import { MemberListResponse } from 'models/memberList';
import { Patient, User, UserRole } from 'models/user';
import {
  setPermanentlyDeleteProgress,
  setHoldProgress,
  setReassignClinicianProgress,
} from 'store/admin/accountActions';
import { AccountStatusType } from 'models/accountInvitation';
import { apiBaseQuery } from 'services/base';
import { RootState } from 'store/rootReducer';
import fetchOnboardedDates from 'services/userReports/helpers/fetchOnboardedDates';

export interface MemberListQueryParams {
  teamType: string[];
  pageNumber: number;
  pageSize: number;
  partOfName?: string;
  sortingField: string;
  sortOrder: 'asc' | 'desc';
  userStatus?: AccountStatusType;
}

interface MemberDischargeQueryParams {
  userIds: string[];
  isInTheTenant: boolean;
}

interface MemberHoldQueryParams {
  userIds: string[];
  isOnHold: boolean;
}

interface MemberDeleteQueryParams {
  members: User[];
}

interface MultipleActionResponse {
  successMembers: string[];
  errorMembers: string[];
}

interface SendMessageQueryParams {
  to: string;
  subject: string;
  content: string;
}

interface ReAssignRoleQueryParams {
  userId: string;
  rolesToRemove?: UserRole[];
  rolesToAdd?: UserRole[];
}

interface AssignClinicianQueryParams {
  id: string;
  clinicianId: string;
  teamType: 'PRIMARY' | 'SUPPORT';
  payload?: any;
}

interface ReAssignClinicianQueryParams {
  memberId: string;
  userIdToRemove: string;
  userIdToAssign: string;
  teamType: 'PRIMARY' | 'SUPPORT';
}

const rolesPath: { [key in UserRole]: string } = {
  clinician: '/isMd',
  coach: '/isCoach',
  md: '/isMd',
  nurse: '/isNurse',
  specialist: '/isSpecialist',
  support: '/isSupport',
  tenant_admin: '/isAdmin',
  observer: '/isObserver',
  patient: '/isPatient',
};

export const adminMembersApi = registryApi.injectEndpoints({
  endpoints(builder) {
    return {
      fetchMemberList: builder.query<MemberListResponse, MemberListQueryParams>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const params = new URLSearchParams();
          params.append('teamType', queryParams.teamType.join(','));
          params.append('page', queryParams.pageNumber.toString());
          params.append('size', queryParams.pageSize.toString());
          params.append('sortingField', queryParams.sortingField);
          params.append('sortOrder', queryParams.sortOrder);

          if (queryParams.partOfName) {
            params.append('partOfName', queryParams.partOfName);
          }

          if (queryParams.userStatus) {
            params.append('userStatus', queryParams.userStatus);
          }

          const memberListResponse = await baseQuery({
            url: ['/users?', `${params.toString()}`].join(''),
            method: 'GET',
          });

          if (memberListResponse.error) {
            return { error: memberListResponse.error };
          }

          const memberListResponseData = memberListResponse.data as MemberListResponse;

          if (queryParams.teamType.includes('CLINICIAN') && memberListResponseData.content.length) {
            const result: MemberListResponse = {
              ...memberListResponseData,
              content: memberListResponseData.content.map((member) => {
                const roles = member.roles?.filter((role) => role !== 'clinician');
                return {
                  ...member,
                  roles,
                  accountStatus: getAccountStatus(member),
                };
              }),
            };

            return { data: result };
          }

          // fetch member since dates
          const patientIds = memberListResponseData.content.map((member) => member.id);
          const memberSinceKeys: Record<string, string> = await fetchOnboardedDates({
            extraOptions,
            queryApi,
            patientIds,
          });

          const result: MemberListResponse = {
            ...memberListResponseData,
            content: memberListResponseData.content.map((member) => {
              const memberSince = memberSinceKeys[member.id] ?? undefined;
              const accountStatus = getAccountStatus({
                ...member,
                memberSince,
              });
              return {
                ...member,
                accountStatus,
                memberSince,
              };
            }),
          };

          return { data: result };
        },
        providesTags: ['UserSuggestion'],
      }),

      deleteMembers: builder.mutation<MultipleActionResponse, MemberDeleteQueryParams>({
        async queryFn({ members }, queryApi, extraOptions, baseQuery) {
          const userIds = members.map((member) => member.id as string);

          const successMembers: string[] = [];
          const errorMembers: string[] = [];

          const requestCount = userIds.length;
          let progressPerRequest = 0;
          let currentProgress = 0;

          queryApi.dispatch(setPermanentlyDeleteProgress(currentProgress));

          for (const userId of userIds) {
            const response = await baseQuery({
              url: `/users/${userId}`,
              method: 'DELETE',
            });

            if (response.error) {
              errorMembers.push(userId);
            } else {
              const currentMember = members.find((member) => member.id === userId);
              successMembers.push(currentMember?.contactInfo?.email as string);
            }

            currentProgress += 1;
            progressPerRequest = (currentProgress / requestCount) * 100;
            await queryApi.dispatch(setPermanentlyDeleteProgress(progressPerRequest));
          }

          return { data: { successMembers, errorMembers } };
        },
        invalidatesTags: ['UserSuggestion'],
      }),

      dischargeRestoreMembers: builder.mutation<MultipleActionResponse, MemberDischargeQueryParams>(
        {
          async queryFn({ userIds, isInTheTenant }, queryApi, extraOptions, baseQuery) {
            const dischargeRestorePatientsResponse = await Promise.all(
              userIds.map((userId) =>
                baseQuery({
                  url: `/users/${userId}`,
                  method: 'PATCH',
                  headers: {
                    'Content-Type': 'application/json-patch+json',
                  },
                  data: [
                    {
                      op: 'replace',
                      path: '/isInTheTenant',
                      value: isInTheTenant,
                    },
                  ],
                })
              )
            );

            // TODO: remove this when BE has this in place
            const tenant = (queryApi.getState() as RootState).auth.tenant;
            await Promise.all(
              userIds.map((userId) =>
                apiBaseQuery('/factuary/api/v2/')(
                  {
                    url: `users/${userId}/actions/all`,
                    method: 'PUT',
                    data: {
                      tenant_id: tenant?.tenant_ID,
                      op: isInTheTenant ? 'restore' : 'discharge',
                    },
                  },
                  queryApi,
                  extraOptions
                )
              )
            );

            const successMembers: string[] = [];
            const errorMembers: string[] = dischargeRestorePatientsResponse
              .filter((response) => response.error)
              .map((response) => response.error as string);

            for (const dischargeRestorePatientResponse of dischargeRestorePatientsResponse) {
              if (dischargeRestorePatientResponse.data) {
                const email = (dischargeRestorePatientResponse.data as User).contactInfo?.email;
                const userId = (dischargeRestorePatientResponse.data as User).id;
                successMembers.push(email || userId);
              }
            }

            return { data: { successMembers, errorMembers } };
          },
          invalidatesTags: ['UserSuggestion'],
        }
      ),

      holdRestartMembers: builder.mutation<MultipleActionResponse, MemberHoldQueryParams>({
        async queryFn({ userIds, isOnHold }, queryApi, extraOptions, baseQuery) {
          const successMembers: string[] = [];
          const errorMembers: string[] = [];

          const requestCount = userIds.length;
          let progressPerRequest = 0;
          let currentProgress = 0;

          queryApi.dispatch(setHoldProgress(currentProgress));

          for (const userId of userIds) {
            const response = await baseQuery({
              url: `/users/${userId}`,
              method: 'PATCH',
              headers: {
                'Content-Type': 'application/json-patch+json',
              },
              data: [
                {
                  op: 'replace',
                  path: '/isOnHold',
                  value: isOnHold,
                },
              ],
            });

            const tenant = (queryApi.getState() as RootState).auth.tenant;
            await Promise.all(
              userIds.map((userId) =>
                apiBaseQuery('/factuary/api/v2/')(
                  {
                    url: `users/${userId}/actions/all`,
                    method: 'PUT',
                    data: {
                      tenant_id: tenant?.tenant_ID,
                      op: isOnHold ? 'hold' : 'restart',
                    },
                  },
                  queryApi,
                  extraOptions
                )
              )
            );

            if (response.error) {
              errorMembers.push(userId);
            } else {
              const email = (response.data as User).contactInfo?.email;
              successMembers.push(email || userId);
            }
            currentProgress += 1;
            progressPerRequest = (currentProgress / requestCount) * 100;
            await queryApi.dispatch(setHoldProgress(progressPerRequest));
          }

          return { data: { successMembers, errorMembers } };
        },
        invalidatesTags: ['UserSuggestion'],
      }),

      sendMessageToMember: builder.mutation<string, SendMessageQueryParams>({
        query: (params) => ({
          url: '/emails',
          method: 'POST',
          data: params,
        }),
        invalidatesTags: ['UserSuggestion'],
      }),

      reassignRole: builder.mutation<User, ReAssignRoleQueryParams>({
        async queryFn(
          { userId, rolesToAdd = [], rolesToRemove = [] },
          queryApi,
          extraOptions,
          baseQuery
        ) {
          const reassignRoleResponse = await baseQuery({
            url: `/users/${userId}`,
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json-patch+json',
            },
            data: [
              rolesToRemove.map((roleToRemove) => ({
                op: 'replace',
                path: rolesPath[roleToRemove],
                value: false,
              })),
              rolesToAdd.map((roleToAdd) => ({
                op: 'replace',
                path: rolesPath[roleToAdd],
                value: true,
              })),
            ].flat(),
          });

          if (reassignRoleResponse.error) {
            console.error('Error reassigning role', reassignRoleResponse.error);
          }

          return { data: reassignRoleResponse.data as User };
        },
        invalidatesTags: ['UserSuggestion'],
      }),

      fetchTeamMember: builder.query<User[], { memberId: string; team?: 'PRIMARY' | 'SUPPORT' }>({
        query: (queryParams) => ({
          url: `/users/${queryParams.memberId}/clinicians?teamTypes=${queryParams?.team || ''}`,
          method: 'GET',
        }),
        providesTags: ['PatientTeams'],
        transformResponse: (response: User[]) => uniqBy(response, 'id'),
      }),

      assignClinicianToPatient: builder.mutation<Patient, AssignClinicianQueryParams>({
        query: (params) => ({
          url: `/users/${params.clinicianId}/patients/${params.id}/full-assignment?teamType=${
            params.teamType || 'PRIMARY'
          }`,
          method: 'PUT',
          data: params.payload || {},
        }),
        invalidatesTags: ['UserSuggestion'],
      }),
      reassignClinician: builder.mutation<unknown, ReAssignClinicianQueryParams>({
        async queryFn(
          { memberId, userIdToAssign, userIdToRemove, teamType },
          queryApi,
          extraOptions,
          baseQuery
        ) {
          const requestCount = 2;
          let progressPerRequest = 0;
          let currentProgress = 0;

          queryApi.dispatch(setReassignClinicianProgress(currentProgress));

          const responseAddTeamMember = await baseQuery({
            url: `/users/${userIdToAssign}/patients/${memberId}/full-assignment?teamType=${
              teamType || 'PRIMARY'
            }`,
            method: 'PUT',
            data: {},
          });

          if (responseAddTeamMember.error) {
            return { error: responseAddTeamMember.error };
          }

          currentProgress += 1;
          progressPerRequest = (currentProgress / requestCount) * 100;
          await queryApi.dispatch(setReassignClinicianProgress(progressPerRequest));

          const responseDeleteTeamMember = await baseQuery({
            url: `/users/${userIdToRemove}/patients/${memberId}?teamType=${teamType || 'PRIMARY'}`,
            method: 'DELETE',
          });

          if (responseDeleteTeamMember.error) {
            return { error: responseDeleteTeamMember.error };
          }

          currentProgress += 1;
          progressPerRequest = (currentProgress / requestCount) * 100;
          await queryApi.dispatch(setReassignClinicianProgress(progressPerRequest));

          return responseAddTeamMember;
        },
        invalidatesTags: ['UserSuggestion'],
      }),

      fetchClinicianSubordinates: builder.query<User[], { clinicianId: string }>({
        query: (queryParams) => ({
          url: `/users/${queryParams.clinicianId}/subordinate`,
          method: 'GET',
        }),
        providesTags: ['UserSubordinate'],
      }),
      addClinicianSubordinate: builder.mutation<
        void,
        { clinicianId: string; subordinateId: string }
      >({
        query: (queryParams) => ({
          url: `/users/${queryParams.clinicianId}/subordinate/${queryParams.subordinateId}`,
          method: 'PUT',
        }),
        invalidatesTags: ['UserSubordinate', 'UserSuggestion'],
      }),
      deleteClinicianSubordinate: builder.mutation<
        void,
        { clinicianId: string; subordinateId: string }
      >({
        query: (queryParams) => ({
          url: `/users/${queryParams.clinicianId}/subordinate/${queryParams.subordinateId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['UserSubordinate', 'UserSuggestion'],
      }),
    };
  },
});

function getAccountStatus(member: User) {
  if (member.isInTheTenant !== null && !member.isInTheTenant) {
    return 'DISCHARGED';
  }

  if (member.isOnHold) {
    return 'HOLDING';
  }

  if (member.isInTheTenant === true) {
    if (member.memberSince) {
      return 'ACTIVE';
    }
    return 'CREATED';
  }

  return undefined;
}

export const {
  useFetchMemberListQuery,
  useDeleteMembersMutation,
  useDischargeRestoreMembersMutation,
  useHoldRestartMembersMutation,
  useSendMessageToMemberMutation,
  useReassignRoleMutation,
  useFetchTeamMemberQuery,
  useAssignClinicianToPatientMutation,
  useReassignClinicianMutation,
  useFetchClinicianSubordinatesQuery,
  useAddClinicianSubordinateMutation,
  useDeleteClinicianSubordinateMutation,
} = adminMembersApi;
